import React from "react";
import { Outlet } from "react-router-dom";
import { Header } from "../molecules/Header";

export const Root = () => {
  return (
    <>
      <div className="flex h-screen justify-center items-center bg-blue-500 mx-auto sm:px-6 lg:px-8">
        <Outlet />
      </div>
    </>
  );
};
