import React, { Fragment } from "react";

import logo from "../assets/bundle-blue.png";
import { Menu, Transition } from "@headlessui/react";
import { Avatar } from "../components";
import { useForm } from "react-hook-form";
import { Navigate, useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const userNavigation = [
  {
    name: "Legal",
    href: "#",
    onClick: () => {
      console.log("Legal Terms");
    },
  },
  {
    name: "Contact",
    href: "#",
    onClick: () => {
      console.log("Contact Us");
    },
  },
  {
    name: "FAQ",
    href: "#",
    onClick: () => {
      console.log("Open FAQ");
    },
  },
  {
    name: "Log Out",
    href: "#",
    onClick: () => {
      console.log("Log out");
    },
  },
];

const Callsign = () => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const onSubmit = (data) => {
    console.log("Submitted");
    navigate("/onboarding ", { replace: true });
  };

  return (
    <>
      <div className="overflow-hidden sm:rounded-lg sm:mx-auto sm:w-full sm:max-w-lg bg-blue-50 shadow">
        {/* Header */}
        <div className="px-4 pt-7 sm:px-6">
          {/* Header Content */}
          <div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
            <div className="ml-4 mt-2">
              <img className="h-8 w-auto" src={logo} alt="bundle" />
            </div>
            <div className="ml-4 mt-2 flex-shrink-0">
              {/* Profile dropdown */}
              <Menu as="div" className="relative ml-3">
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                    <span className="sr-only">Open user menu</span>
                    <Avatar
                      // imageSource={imgUrl}
                      firstName={"John"}
                      lastName={"Smith"}
                      size={"normal"}
                      color={"lightBlue"}
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <a
                            href={item.href}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700"
                            )}
                            onClick={item.onClick}
                          >
                            {item.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        {/* Body */}
        <div className="px-4 py-5 sm:px-6">
          <div>
            <h1 className="text-left text-3xl font-light tracking-wide leading-tight text-neutral-800">
              Choose your team's callsign
            </h1>
            <p className="mt-1 text-sm text-gray-500">
              Pick your team's Bundle callsign to use in custom URLs
            </p>
          </div>
          {/* Form */}
          <form className="space-y-6 py-4">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-700"
              >
                Callsign
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">
                    hellobundle.com/
                  </span>
                </div>
                <input
                  type="text"
                  name="company-website"
                  id="company-website"
                  className="block w-full rounded-md border-0 py-1.5 pl-36 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-600 sm:pl-32 sm:text-sm sm:leading-6"
                  placeholder="mycompany"
                />
              </div>
              <p
                className="mt-1 text-sm text-gray-500"
                id="callsign-description"
              >
                Numbers, letters, and dashes only please
              </p>
            </div>
          </form>
        </div>
        {/* Footer */}
        <div className="px-4 pb-6 sm:px-6">
          {/* Footer Content */}
          <button
            type="submit"
            className="rounded-full bg-blue-600 py-2.5 px-8 text-sm font-normal text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            onClick={handleSubmit(onSubmit)}
          >
            Looks Good
          </button>
        </div>
      </div>
    </>
  );
};

export default Callsign;
