import { configureStore, combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import organizationReducer from "../features/organization/organizationSlice";

// Create Root Reducer
const rootReducer = combineReducers({
  organization: organizationReducer,
});

// Redux Persistance Config
const persistConfig = {
  key: "root",
  storage,
  blacklist: [],
};

// Create Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
