import api from "..";

// Get Organization by ID
const getOrganization = async (id) => {
  const response = await api.get(`/organizations/${id}`);
  return response.data;
};

const organizationService = {
  getOrganization,
};

export default organizationService;
