import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  // RouterProvider,
  // createBrowserRouter,
  // createRoutesFromElements,
} from "react-router-dom";
import Cookies from "universal-cookie";
import { Layout, Root } from "./components";
import Company from "./pages/Company";
import Compliance from "./pages/Compliance";
import Onboarding from "./pages/Onboarding";
import Callsign from "./pages/Callsign";
import Apply from "./pages/Apply";

function App() {
  // const cookies = new Cookies();

  // cookies.set("myName", "Aman", {
  //   domain: ".localhost:3005.com",
  // });
  // console.log(cookies.get("myName"));

  return (
    <>
      {/* <div className="App"></div> */}
      <Router>
        <Routes>
          <Route path="/" element={<Apply />} />
          <Route element={<Root />}>
            <Route path="/company" element={<Company />} />
            <Route path="/compliance" element={<Compliance />} />
            <Route path="/callsign" element={<Callsign />} />
          </Route>
          <Route element={<Layout />}>
            <Route path="/onboarding" element={<Onboarding />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
