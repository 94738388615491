import React, { Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import { Avatar } from "../../components";
import { useForm } from "react-hook-form";

import logo from "../../assets/bundle-white.png";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const userNavigation = [
  {
    name: "Legal",
    href: "#",
    onClick: () => {
      console.log("Legal Terms");
    },
  },
  {
    name: "Contact",
    href: "#",
    onClick: () => {
      console.log("Contact Us");
    },
  },
  {
    name: "FAQ",
    href: "#",
    onClick: () => {
      console.log("Open FAQ");
    },
  },
  {
    name: "Log Out",
    href: "#",
    onClick: () => {
      console.log("Log out");
    },
  },
];

export const Header = () => {
  return (
    <>
      <header className="fixed top-0 left-0 right-0">
        <nav
          className="mx-auto flex max-w-8xl items-center justify-between p-6 lg:px-12"
          aria-label="Global"
        >
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <img className="h-12 w-auto" src={logo} alt="bundle" />
          </a>
          <div className="ml-4 mt-2 flex-shrink-0">
            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                  <span className="sr-only">Open user menu</span>
                  <Avatar
                    // imageSource={imgUrl}
                    firstName={"John"}
                    lastName={"Smith"}
                    size={"normal"}
                    color={"lightBlue"}
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item) => (
                    <Menu.Item key={item.name}>
                      {({ active }) => (
                        <a
                          href={item.href}
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                          onClick={item.onClick}
                        >
                          {item.name}
                        </a>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </nav>
      </header>
    </>
  );
};
