import React from "react";
import { Header } from "../components";

const Onboarding = () => {
  return (
    <>
      <Header />
      <div className="flex justify-center items-center bg-blue-500">
        <div className="w-1/3">
          <div className="px-4 pt-48 pb-48 sm:px-6">
            <div>
              <h1 className="text-left text-6xl font-normal tracking-wide text-neutral-50">
                Great, <br />
                let's keep going
              </h1>
              <p className="mt-4 text-base font-medium text-neutral-100 leading-relaxed tracking-wide">
                Before we can open your account, we need to learn more about
                your company and your team.
              </p>
            </div>
            <div className="py-9">
              {/* Footer Content */}
              <button
                type="submit"
                className="rounded-full bg-blue-600 py-2.5 px-8 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                // onClick={handleSubmit(onSubmit)}
              >
                Continue
              </button>
            </div>
            <div>
              <p className="text-base font-normal text-neutral-100 leading-relaxed tracking-wide">
                Your progress is automatically saved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Onboarding;
